import axios from 'axios'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'

export const request = async (url, method, payload, formData, params) => {
    // const no_permission = t('You do not have access rights')

    const api = process.env['REACT_APP_BASE_URL']

    const lang = localStorage.getItem('language')

    const languages = {
        sy: 'ar',
        ir: 'fa',
        en: 'en',
        ru: 'ru',
        pt: 'pt',
        ky: 'ky',
        tg: 'tg',
        ur: 'ur'
    }

    const finally_url = `${api}${languages[lang] || 'en'}/api/${url}`

    try {
        const res = await axios({
            url: finally_url,
            headers: {
                ...(formData && { 'Content-Type': 'multipart/form-data' })
            },
            method,
            data: payload,
            params
        })

        return res.data
    } catch (error) {
        if (error.response) {
            Sentry.captureException(error.response)
        } else if (!error.response?.status) {
            Sentry.captureException(error)
        }

        if (error.response && error.response?.status === 403) {
            toast.warning('You do not have access rights')
        }
        throw error
    }
}

export async function createNewBranch(id, payload) {
    return request(`branches/${id}/`, 'POST', payload)
}

export async function userBranches() {
    return request(`user-branches/`, 'GET')
}

export async function deleteBranch(org_id, id) {
    return request(`branches/${org_id}/${id}/`, 'DELETE')
}

export async function getTimezones() {
    return request(`timezones/`, 'GET')
}

export async function getCountries() {
    return request(`countries/`, 'GET')
}

export async function getProvinces(id) {
    return request(`provinces/?country=${id}`, 'GET')
}

export async function getDistricts(id) {
    return request(`districts/?province=${id}`, 'GET')
}

export async function getGenders() {
    return request(`genders/`, 'GET')
}

export async function getBusinessIndustries() {
    return request(`business-industries/`, 'GET')
}

export async function getBusinessTypes() {
    return request(`business-types/`, 'GET')
}

export async function getCurrencies() {
    return request(`currencies/`, 'GET')
}
export async function getInvestmentTypes() {
    return request(`ask-types/`, 'GET')
}
export async function getUtilizationTypes() {
    return request(`utilization-types/`, 'GET')
}

export async function getLanguages() {
    return request(`languages/`, 'GET')
}

export async function getOrganizationUsers(id) {
    return request(`user-organizations/${id}/`, 'GET')
}

export async function getUsersByBranch(branch_id, filters = null) {
    return request(`users/${branch_id}/`, 'GET', null, null, filters)
}
export async function getUsersByBranchV2(branch_id, filters = null) {
    return request(`v2/users/${branch_id}/`, 'GET', null, null, filters)
}

export async function createNewOrganization(payload) {
    return request(`organizations/`, 'POST', payload)
}

export async function updateOrganization(id, payload) {
    return request(`organizations/${id}/`, 'PUT', payload)
}

export async function createNewAdmin(payload) {
    return request(`users/`, 'POST', payload)
}
